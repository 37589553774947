.navbar-pasarela{
    height: 4vh;
    min-height: 80px;
    background-color: var(--verde);
}

.portada-pasarela {
	/* background-image: url("../../../public/images/portada-full.jpeg"); */
	/* background-size: cover; */
	/* background-repeat: no-repeat; */
	/* height: 96vh; */
	min-height: 770px;
}

:root {
	--border-color-pay: #adb5bd;
	--kt-input-solid-color: black;
}

.size-spinner {
	height: 1.3rem;
    width: 1.3rem;
}

.cursor-mouse {
	cursor: pointer!important;
}

.kr-embedded .kr-field-element {
	border: 1px solid var(--border-color-pay) !important;
	box-shadow: none !important;
	color: var(--kt-input-solid-color);
	border-radius: 0.625rem;
	transition: color 0.2s ease;
	padding: 6px !important;
	height: 100% !important;
}
.kr-embedded .kr-payment-button {
	color: #fff !important;
	border-radius: 3px;
	background-color: #243f8e !important;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.kr-embedded .kr-payment-button:hover {
	box-shadow: 0px 5px 18px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.kr-embedded .kr-field-wrapper {
	height: 100% !important;
}
@keyframes scaleAnimation {
	0% {
		opacity: 0;
		transform: scale(1.5);
   }
	100% {
		opacity: 1;
		transform: scale(1);
   }
}
@keyframes drawCircle {
	0% {
		stroke-dashoffset: 151px;
   }
	100% {
		stroke-dashoffset: 0;
   }
}
@keyframes drawCheck {
	0% {
		stroke-dashoffset: 36px;
   }
	100% {
		stroke-dashoffset: 0;
   }
}
@keyframes fadeOut {
	0% {
		opacity: 1;
   }
	100% {
		opacity: 0;
   }
}
@keyframes fadeIn {
	0% {
		opacity: 0;
   }
	100% {
		opacity: 1;
   }
}
#successAnimationCircle {
	stroke-dasharray: 151px 151px;
	stroke: #53d635;
}
#successAnimationCheck {
	stroke-dasharray: 36px 36px;
	stroke: #53d635;
}
#successAnimation.animated {
	animation: 1s ease-out 0s 1 both scaleAnimation;
}
#successAnimation.animated #successAnimationCircle {
	animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both;
}
#successAnimation.animated #successAnimationCheck {
	animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both;
}
#replay {
	background: rgba(255, 255, 255, 0.2);
	border: 0;
	border-radius: 3px;
	bottom: 100px;
	color: #fff;
	left: 50%;
	outline: 0;
	padding: 10px 30px;
	position: absolute;
	transform: translateX(-50%);
}
#replay:active {
	background: rgba(255, 255, 255, 0.1);
}
