@font-face {
    font-family: BrandingSF;
    font-weight: 500;
    font-display: swap;
    src: local("BrandingSF-Medium"), url("./fonts/BrandingSF-Medium.woff") format("woff");
}

@font-face {
    font-family: BrandingSF;
    font-weight: 800;
    font-display: swap;
    src: local("BrandingSF-Black"), url("./fonts/BrandingSF-Black.woff") format("woff");
}

@font-face {
    font-family: BrandingSF;
    font-weight: 700;
    font-display: swap;
    src: local("BrandingSF-Bold"), url("./fonts/BrandingSF-Bold.woff") format("woff");
}

@font-face {
    font-family: BrandingSF;
    font-weight: 600;
    font-display: swap;
    src: local("BrandingSF-SemiBold"), url("./fonts/BrandingSF-SemiBold.woff") format("woff");
}

@font-face {
    font-family: BrandingSF;
    font-weight: 400;
    font-display: swap;
    src: local("BrandingSF-SemiLight"), url("./fonts/BrandingSF-SemiLight.woff") format("woff");
}

* {
    margin: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    font-family: "BrandingSF", sans-serif;
    line-height: inherit;
    /* background-color: red; */
}
