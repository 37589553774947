:root {
	--azul: #1B1D36;
	--verde: #00B74F;
	--azul-rgba: rgba(27, 29, 54, .9);
	--verde-rgba: rgba(0, 183, 79, .9);
	--blanco: #ffffff;
	--verde-accordeon-active: #00b74fab;
	--azul-accordeon-active: #1b1d36ea;
	--verde-accordeon-border-color: #00b74fcd;
	--azul-accordeon-border-color: #1b1d36c1;
	--verde-accordeon-border-width: 1px;
	--azul-accordeon-border-width: 1px;
}

input {
	border-color: #c3c5ca !important;
	box-shadow: none !important;
}

.portada-full {
	background-image: url("../../../public/images/portada-full.jpeg");
	background-size: cover;
	background-repeat: no-repeat;
	height: 100vh;
	min-height: 850px;
}

.portada {
	background: url("../../../public/images/PORTADA.png");
	background-size: cover;
	background-repeat: no-repeat;
	height: 65vh;
	min-height: 650px;
}

.bottom {
	background: url("../../../public/images/CURSO-PRESENCIAL.png");
	background-size: cover;
	background-repeat: no-repeat;
}

.fnt-1 {
	font-size: 5rem;
	font-weight: 600;
	display: block;
}

.fnt-2 {
	font-size: 3.6rem;
	font-weight: 600;
	margin-bottom: 0;
	display: block;
}

.fnt-3 {
	font-size: 1.6rem;
	font-weight: 500;
	margin-bottom: 0;
	display: block;
}

.fnt-4 {
	font-size: 2.4rem;
	font-weight: 600;
	margin-bottom: 0;
	color: var(--verde);
	display: block;
}

.fnt-5 {
	font-size: 3rem;
	font-weight: 600;
	margin-bottom: 0;
	display: block;
}

.fnt-p {
	font-size: 1.2rem;
	text-align: justify;
}

.fnt-p2 {
	font-size: 1.1rem;
	text-align: justify;
}

.fnt-t {
	font-size: 2.6rem;
}

.fnt-t2 {
	font-size: 3.2rem;
}

.fnt-t3 {
	font-size: 2.4rem;
}

.franja {
	background: url("../../../public/images/DEGRADADO-VERDE.png");
	background-size: cover;
	background-repeat: no-repeat;
	height: 60px;
}

.franja-font {
	padding-top: .3rem;
	font-size: 2.6rem;
	font-weight: 500;
	margin-bottom: 0;
	display: block;
}

.bg-azul {
	background-color: var(--azul);
}

.bg-verde {
	background-color: var(--verde);
}

.bg-success {
	background-color: var(--verde) !important;
}

.txt-azul {
	color: var(--azul);
}

.txt-verde {
	color: var(--verde) !important;
}

.btn-success{
	background-color: var(--verde);
	border: none;
}

.btn-success:hover{
	background-color: var(--verde-rgba);
}

.btn-success:focus{
	background-color: var(--verde-rgba);
}

.btn-primary{
	background-color: var(--azul);
	border: none;
}

.btn-primary:hover{
	background-color: var(--azul-rgba);
}

.btn-primary:focus{
	background-color: var(--azul-rgba);
}

.btn-primary:active{
	background-color: var(--azul-rgba) !important;
}

ul {
	list-style: none;
	font-size: 1.1rem;
	padding-right: 1rem;
}

/*** ESTILOS BOTÓN VERDE ***/
.ov-btn-grow-ellipse {
	background: transparent;
	/* color de fondo */
	color: var(--verde);
	/* color de fuente */
	border: 3px solid var(--verde);
	/* tamaño y color de borde */
	padding: 16px 40px;
	border-radius: 3px;
	/* redondear bordes */
	position: relative;
	z-index: 1;
	overflow: hidden;
	display: inline-block;
	text-decoration: none;
	font-size: 2.2rem;
	border-radius: 50px;
	font-weight: 600;
}

.ov-btn-grow-ellipse:hover {
	color: var(--blanco);
	/* color de fuente hover */
}

.ov-btn-grow-ellipse::after {
	content: "";
	background: var(--verde);
	/* color de fondo hover */
	position: absolute;
	z-index: -1;
	padding: 16px 20px;
	display: block;
	border-radius: 50%;
	left: -50%;
	right: -50%;
	top: -150%;
	bottom: -150%;
	transform: scale(0, 0);
	transition: all 0.4s ease;
}

.ov-btn-grow-ellipse:hover::after {
	transition: all 0.6s ease-out;
	transform: scale(1, 1);
}

/*** ESTILOS BOTÓN AZUL ***/
.ov-btn-azul-ellipse {
	background: var(--azul);
	/* color de fondo */
	color: var(--blanco);
	/* color de fuente */
	border: 3px solid var(--azul);
	/* tamaño y color de borde */
	padding: 8px 40px;
	border-radius: 3px;
	/* redondear bordes */
	position: relative;
	z-index: 1;
	overflow: hidden;
	display: inline-block;
	text-decoration: none;
	font-size: 2rem;
	border-radius: 50px;
	font-weight: 600;
	width: 80%;
}

.accordion-button:not(.collapsed){
	color: var(--blanco);
	font-weight: 600;
    background-color: var(--azul);
}

.accordion-button:focus{
	border-color: var(--azul);
	box-shadow: var(--azul);
}

@media screen and (max-width: 1420px) {
    .portada {
        height: 70vh !important;
    }
}

@media screen and (max-width: 768px) {
    .portada {
		background: url("../../../public/images/fondo-cel.webp");
        height: 80vh !important;
    }

	.fnt-1 {
		font-size: 3rem;
		font-weight: 600;
		display: block;
	}
}