.navbar-gracias{
    height: 6vh;
    /* min-height: 80px; */
    background-color: var(--verde);
}

.portada-gracias {
	background-image: url("../../../public/images/BIENVENIDO.png");
	background-size: cover;
	background-repeat: no-repeat;
    background-position: center;
	height: 94vh;
	min-height: 850px;
}

.t-shadow{
    text-shadow: 0 0 0.2em #000;
}